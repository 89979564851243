import React, { useState, useEffect, useReducer } from "react";
import { useLocation, useParams, useHistory } from "react-router";
import { Link, Redirect } from "react-router-dom";
import Layout from "../layout/Layout";
import Loader from "../../base/layout/Loader";
import { useStateReducer } from "../../base/context/authContext";
import { useForm } from "react-hook-form";
import { hours, mins } from "../helpers/lists";
import { ErrorMsg } from "../components/ErrorMsg"
import { formatCurrencyNoSign, validateData, validateStartData } from "../helpers/helper";
import dayjs from "dayjs";

import ApiClient from "../../base/api/client";
import CancelJobForm from "./Partner/forms/CancelJobForm";
import RescheduleJobForm from "./Partner/forms/RescheduleJobForm";
import EavesPlusFiveForm from "./Partner/forms/EavesPlusFiveForm";
import PastJobs from "./components/PastJobs"

const initialState = {
  w1Checked: '',
  w2Checked: '',
  w3Checked: '',
  w4Checked: '',
  ehChecked: '',
  egChecked: '',
  cagesChecked: '',
  customChecked: '',
  customDesc: '',
  workTimes: '',
  estMins: ''
}

const reducer = (state, action) => {
  switch(action.type){
    case 'check_w1':
      return {...state, w1Checked: action.value}
    case 'check_w2':
      return {...state, w2Checked: action.value}
    case 'check_w3':
      return {...state, w3Checked: action.value}
    case 'check_w4':
      return {...state, w4Checked: action.value}
    case 'check_eh':
      return {...state, ehChecked: action.value}
    case 'check_eg':
      return {...state, egChecked: action.value}
    case 'check_cages':
      return {...state, cagesChecked: action.value}
    case 'check_custom':
      return {...state, customChecked: action.value}
    case 'work_times':
      return {...state, workTimes: action.value}
    case 'est_mins':
      return {...state, estMins: action.value}
      // case 'custom_desc':
    //   return {...state, customDesc: action.value}
    default:
      return state
    }

}


const JobDetails = () => {

  const [state, dispatch] = useReducer(reducer, initialState);
  const history = useHistory();

  const params = useParams();
  const [crew, setCrew] = useState(null);
  const [job, setJob] = useState(null);
  const [pastJobs, setPastJobs] = useState([]);
  const [property, setProperty] = useState(null);
  const [cfid, setCfid] = useState(null);
  const [client, setClient] = useState(null);
  const [address, setAddress] = useState(null);
  const [phone, setPhone] = useState(null);
  const [cellPhone, setCellPhone] = useState(null);
  const [nextDests, setNextDests] = useState([]);
  const [prices, setPrices] = useState(null);
  const [times, setTimes] = useState(false);
  const [chqNumClass, setChqNumClass] = useState('column is-3 is-5-mobile is-hidden');
  const [errorMsgs, setErrorMsgs] = useState({});
  const [showSubmitButton, setShowSubmitButton] = useState(null);
  const [buttonText, setButtonText] = useState(null);
//  const [{ state_crew, userLoading }] = useStateReducer();

  const [{ crew: state_crew }] = useStateReducer();
  const [notes, setNotes] = useState(null);
  const [jobPics, setJobPics] = useState([]);
  const [propertyPics, setPropertyPics] = useState([]);
  const [preJobNote, setPreJobNote] = useState(null);
  const [postJobNote, setPostJobNote] = useState(null);
  const [holdingCell, setHoldingCell] = useState(false);
  const [showCash, setShowCash] = useState('is-hidden');
  const [showCashAmount, setShowCashAmount] = useState('is-hidden');
  const [email, setEmail] = useState('');
  const [showLoader, setShowLoader] = useState(true);
  const [unStartClass, setUnStartClass] = useState('button is-danger is-hidden');
  const [unReportClass, setUnReportClass] = useState('button is-danger is-hidden');
  const [cancelClass, setCancelClass] = useState('button is-danger is-hidden');
  const [rescheduleClass, setRescheduleClass] = useState('button is-danger is-hidden');
  const [eavesPlusFiveClass, setEavesPlusFiveClass] = useState('button is-danger is-hidden');
  const [showCancelForm, setShowCancelForm] = useState(false);
  const [showRescheduleForm, setShowRescheduleForm] = useState(false);
  const [showEavesPlusFiveForm, setShowEavesPlusFiveForm] = useState(false);

  const ccForm = useForm();
  const { register, handleSubmit, setValue, getValues } = ccForm;
  const {
    startHour,
    startMinute,
    startHourPrior,
    startMinutePrior,
    finishHour,
    finishMinute
  } = getValues();
  let workMinutes;
  const pad = 2;

  const fetchJobData = async () => {
    setShowLoader(true);
    console.log('Result Starting  ', result);
    const result = await ApiClient.request(`/api/jobs/${params.id}`);
    console.log('Result', result);
    const job_pics = await ApiClient.request(`/api/ben/${params.id}/job_images`);
    console.log('Job Pics', job_pics);
    setJobPics(job_pics);
    const property_pics = await ApiClient.request(`/api/ben/${result.property.jobinfoid}/property_images`);
    console.log('Property Pics', property_pics);
    setPropertyPics(property_pics);
    setJob(result.job);
    setProperty(result.property);
    setCfid(result.cfid);
    setClient(result.client);
    setAddress(result.address);
    setPhone(result.phone);
    setCellPhone(result.cellphone);
    setPrices(result.prices);
    setNextDests(result.nextDests);
    setNotes(result.notes);
    setPreJobNote(result.pre_job_note);
    setPostJobNote(result.post_job_note);
    setHoldingCell(result.holding_cell);
    setEmail(result.email);
    setPastJobs(result.completed_jobs);

    if(result.workTimes.startMinute != null && result.workTimes.startMinute.toString().length<2){
      result.workTimes.startMinute = '0' + result.workTimes.startMinute.toString();
    }
    console.log('FINISH MIN **:', result.workTimes.finishMinute);

    if(result.workTimes.finishMinute != null && result.workTimes.finishMinute.toString().length<2){
      result.workTimes.finishMinute = '0' + result.workTimes.finishMinute.toString();
    }
    dispatch({ type: 'work_times', value: result.workTimes });
    dispatch({ type: 'est_mins', value: result.estMins });

    if(!result.job.reportstime){
      setShowSubmitButton(false);
      setButtonText('Start Job First');
    }else{
      setShowSubmitButton(true);
      setButtonText('Return Job');
    }
    setShowLoader(false);
  }

  useEffect(() => {
    console.log('State CREW in USe Effect:', state_crew);
    if(state_crew){
      setCrew(state_crew.crew_name);
      fetchJobData();
    }
  }, [state_crew])

  useEffect(() => {
    setTimes(false);
  }, [times])


  useEffect(() => {
    if(state){
      createjobdesc(false);
    }
  }, [state]);

  useEffect(() => {
    if(prices && job){
      createjobdesc(true);
    }
  }, [prices, job, state.workTimes, state.estMins])

  const fillHoursAndPay = () => {
    const stime = dayjs().hour(Number(startHour)).minute(Number(startMinute));
    const ftime = dayjs().hour(Number(finishHour)).minute(Number(finishMinute));
    workMinutes = ftime.diff(stime, "minutes", true).toFixed(0);
  };
  fillHoursAndPay();

  const setUpPrices = (jobType, actionType) => {
      if(job && job.w1 && jobType==='w1'){
        dispatch({ type: actionType, value: 'checked' });
      }
      if(job && job.w2 && jobType==='w2'){
        dispatch({ type: actionType, value: 'checked' });
      }
      if(job && job.w3 && jobType==='w3'){
        dispatch({ type: actionType, value: 'checked' });
      }
      if(job && job.w4 && jobType==='w4'){
        dispatch({ type: actionType, value: 'checked' });
      }
      if(job && job.eh && jobType==='eh'){
        dispatch({ type: actionType, value: 'checked' });
      }
      if(job && job.eg && jobType==='eg'){
        dispatch({ type: actionType, value: 'checked' });
      }
      if(job && job.custom && jobType==='custom'){
        dispatch({ type: actionType, value: 'checked' });
      }
      if(job && job.cages && jobType==='cages'){
        dispatch({ type: actionType, value: 'checked' });
      }
  }

  const createjobdesc = (initial) => {
      let totalPrice = 0.00;
      let oneZeroPrice = false;
      let jobDescription = '';
      if(initial){
        setUpPrices('w1', 'check_w1');
        setUpPrices('w2', 'check_w2');
        setUpPrices('w3', 'check_w3');
        setUpPrices('w4', 'check_w4');
        setUpPrices('eh', 'check_eh');
        setUpPrices('eg', 'check_eg');
        setUpPrices('custom', 'check_custom');
        setUpPrices('cages', 'check_cages');
        prices && prices.w1 && parseFloat(prices.w1)!==0  ? setValue('w1Text', prices.w1) : setValue('w1Text', '0.00');
        if(job.w1 && parseFloat(job.w1)>0) {
          setValue('w1Text', job.w1);
        }
        prices && prices.eh && parseFloat(prices.eh)!==0 ? setValue('ehText', prices.eh) : setValue('ehText', '0.00');
        if(job.eh && parseFloat(job.eh)>0) {
          setValue('ehText', job.eh);
        }
        prices && prices.eg && parseFloat(prices.eg)!==0 ? setValue('egText', prices.eg) : setValue('egText', '0.00');
        if(job.eg && parseFloat(job.eg)>0) {
          setValue('egText', job.eg);
        }
        job.w2 && parseFloat(job.w2)>0 ? setValue('w2Text', job.w2) : setValue('w2Text', '0.00');
        job.w3 && parseFloat(job.w3)>0 ? setValue('w3Text', job.w2) : setValue('w3Text', '0.00');
        job.w4 && parseFloat(job.w4)>0 ? setValue('w4Text', job.w2) : setValue('w4Text', '0.00');
        job.custom && parseFloat(job.custom)>0 ? setValue('customText', job.custom) : setValue('customText', '0.00');
        job.customdesc ? setValue('customDesc', job.customdesc) : setValue('customDesc', '');

        if(job.cages && job.cages === '-1') {
          setValue('cagesSelect', 'as Reqd');
        } else if(job.cages && parseFloat(job.cages) > '0') {
          setValue('cagesSelect', parseFloat(job.cages)/10);
        }
        setValue('jobDesc', job.jobdesc);
        if(state.workTimes.startHour){
          setValue('startHour', state.workTimes.startHour);
          setValue('startHourPrior', state.workTimes.startHour);
        }

        if(state.workTimes.startMinute != null){
          setValue('startMinute', state.workTimes.startMinute);
          setValue('startMinutePrior', state.workTimes.startMinute);
        }
        if(state.workTimes.finishHour){
          setValue('finishHour', state.workTimes.finishHour);
        }
        console.log('STATE:', state.workTimes);
      if(state.workTimes.finishMinute){
          console.log('FINISH MIN:', state.workTimes.finishMinute);
          setValue('finishMinute', state.workTimes.finishMinute);
        }
        setValue('estMins', state.estMins);
        setValue('clientHome', job.clienthm ? job.clienthm : 'None Selected');
        setValue('lawnSign', job.lawnsign ? job.lawnsign : 'None Selected');
        setValue('recStatus', 'None Selected');
      }

      if(state.w1Checked){
        let w1Price = getValues('w1Text');
        if(prices.w1 && parseFloat(w1Price)>0){
          setValue('w1Text', prices.w1)
        }
        if(w1Price===0){
          oneZeroPrice = true;
        } else {
          totalPrice += parseFloat(w1Price);
        }
        (jobDescription === '' ? jobDescription = 'W1' : jobDescription += ' + W1');
      }
      if(state.w2Checked){
        const w2Price = getValues('w2Text');
        parseFloat(w2Price)===0 ? oneZeroPrice = true : totalPrice += parseFloat(w2Price);
        (jobDescription === '' ? jobDescription = 'W2' : jobDescription += ' + W2');
      }
      if(state.w3Checked){
        const w3Price = getValues('w3Text');
        parseFloat(w3Price)===0 ? oneZeroPrice = true :totalPrice += parseFloat(w3Price);
        (jobDescription === '' ? jobDescription = 'W3' : jobDescription += ' + W3');
      }
      if(state.w4Checked){
        const w4Price = getValues('w4Text');
        (w4Price && parseFloat(w4Price)===0) ? oneZeroPrice = true : totalPrice += parseFloat(w4Price);
        (jobDescription === '' ? jobDescription = 'W4' : jobDescription += ' + W4');
      }
      if(state.ehChecked){
        let ehPrice = getValues('ehText');
        if(prices.eh && parseFloat(ehPrice)>0){
           setValue('ehText', prices.eh)
        }
        parseFloat(ehPrice)===0 ? oneZeroPrice = true : totalPrice += parseFloat(ehPrice);
        (jobDescription === '' ? jobDescription = 'EH' : jobDescription += ' + EH');
      }
      if(state.egChecked){
        const egPrice = getValues('egText');
        parseFloat(egPrice)===0 ? oneZeroPrice = true : totalPrice += parseFloat(egPrice);
        (jobDescription === '' ? jobDescription = 'EG' : jobDescription += ' + EG');
      }
      if(state.customChecked){
        const priceCustom = getValues('customText');
        parseFloat(priceCustom)===0 ? oneZeroPrice = true : totalPrice += parseFloat(priceCustom);
        (jobDescription === '' ? jobDescription = '[Custom]' + getValues('customDesc') : jobDescription += ' + ' + getValues('customDesc'));
      }
      let numCages = getValues('cagesSelect');
      if(state.cagesChecked){
        if(numCages!=='as Reqd'){
          let cagesTotal = parseInt(numCages) * 10;
          totalPrice += cagesTotal;
          setValue('cagesText', cagesTotal);
        } else {
          oneZeroPrice = true;
        }
        (jobDescription === '' ? (jobDescription = 'Install Cages(' + numCages + ')') : (jobDescription += ' + ' + 'Install Cages(' + numCages + ')'));
      }

      if(oneZeroPrice){
        totalPrice = 0;
      }
      setValue('jobDesc', jobDescription);
      setValue('price', formatCurrencyNoSign(totalPrice));
      setValue('hst', formatCurrencyNoSign(totalPrice * .13));
      setValue('totalPrice', formatCurrencyNoSign(totalPrice * 1.13));
  }

  const onChange = (e) => {
    if(e.target.name==='w1Check'){
      (state.w1Checked==='checked' ? dispatch({ type: 'check_w1', value: ''}) : dispatch({ type: 'check_w1', value: 'checked'}));
    } else if(e.target.name==='w2Check'){
      (state.w2Checked==='checked' ? dispatch({ type: 'check_w2', value: ''}) : dispatch({ type: 'check_w2', value: 'checked'}));
    } else if(e.target.name==='w3Check'){
      (state.w3Checked==='checked' ? dispatch({ type: 'check_w3', value: ''}) : dispatch({ type: 'check_w3', value: 'checked'}));
    } else if(e.target.name==='w4Check'){
      (state.w4Checked==='checked' ? dispatch({ type: 'check_w4', value: ''}) : dispatch({ type: 'check_w4', value: 'checked'}));
    } else if(e.target.name==='ehCheck'){
      (state.ehChecked==='checked' ? dispatch({ type: 'check_eh', value: ''}) : dispatch({ type: 'check_eh', value: 'checked'}));
    } else if(e.target.name==='egCheck'){
      (state.egChecked==='checked' ? dispatch({ type: 'check_eg', value: ''}) : dispatch({ type: 'check_eg', value: 'checked'}));
    } else if(e.target.name==='cagesCheck'){
      (state.cagesChecked==='checked' ? dispatch({ type: 'check_cages', value: ''}) : dispatch({ type: 'check_cages', value: 'checked'}));
    } else if(e.target.name==='customCheck'){
      (state.customChecked==='checked' ? dispatch({ type: 'check_custom', value: ''}) : dispatch({ type: 'check_custom', value: 'checked'}));
    } else if(e.target.name==='startHourPrior'){
      setValue('startHour', e.target.value)
    } else if(e.target.name==='startMinutePrior'){
      setValue('startMinute', e.target.value)
    } else if(e.target.name==='startHour'){
      setValue('startHourPrior', e.target.value)
    } else if(e.target.name==='startMinute'){
      setValue('startMinutePrior', e.target.value)
    } else {
      setValue(e.target.name, e.target.value);
    }
    setTimes(true);
    createjobdesc(false);
  };

  const editRecStatus = () => {
    // if esf[:recstatus] == 'Client Did Not Pay'
    //   job.recstatus = 'Receivable'
    // elsif esf[:recstatus] == 'Client Paid Cash'
    //   job.recstatus = 'Receivable - crew (cash)'
    // elsif esf[:recstatus] == 'Client Paid By Cheque'
    //   job.recstatus = 'Receivable - crew (#'+esf[:chqnum]+')'
    // end
    const recStatus55 = getValues('recStatus');
    if(recStatus55.includes('Cheque')){
      setChqNumClass('column is-3 is-5-mobile');
    } else {
      setChqNumClass('column is-3 is-5-mobile is-hidden');
    }
    if(recStatus55.includes('Cash')){
      setShowCash('column is-3 is-5-mobile');
      setShowCashAmount('column is-3 is-5-mobile');
    }else{
      setShowCash('is-hidden');
      setShowCashAmount('is-hidden');
    }

  }

  const nextDestOptions = () => {
    if(nextDests){
      return nextDests.map((dest) => <option key={dest.jobid} value={dest.jobid}>{dest.address}[{dest.num}]-{dest.type}</option>);
    }
  }


  const sendStartJobData = async (updatedData) => {
    const result = await ApiClient.patch(`/api/jobs/${job.jobid}/start_job`, updatedData);
    const msg = 'Job at ' + address + ' started successfully';
    history.push(`/runsheet/${msg}`);
  }

  const startJob = (data) => {
    let temp = validateStartData(data);
    var count= Object.keys(temp).length;
    if(count===0){
      let updatedData = { ...data, hrid: state_crew.crew_hrid, jobid: job.jobid };
      sendStartJobData(updatedData)
    }else{
      setErrorMsgs(temp);
    }
  }

  const sendReturnJobData = async (updatedData) => {
    const result = await ApiClient.patch(`/api/jobs/update`, updatedData);
    let msg = 'Job at ' + address + ' returned successfully';
    if(result.cash_email){
      msg = 'Job at ' + address + ' returned and cash receipt email successfully';
    }
    history.push(`/runsheet/${msg}`);
  }

  const returnJob = (data) => {
    let temp = validateData(data, workMinutes);
    var count= Object.keys(temp).length;
    if(count===0){
      let updatedData = { ...data, hrid: state_crew.crew_hrid, jobid: job.jobid };
      sendReturnJobData(updatedData)
    }else{
      setErrorMsgs(temp);
    }
  }

  const showNotes = () => {
    const notesData = () => (notes.map(note => <tr key={note.id}>
      <td>{ note.notes }</td>
      <td>{ note.id10 }</td>
        <td>{ dayjs(note.ts).format('MMM DD, YYYY hh:mm') }</td>
      <td>{ note.recorder }</td>
    </tr>));

    return <section className="hero is-info">
        <div className="hero-body">
          <p className="title">
            Notes(client, property, job)
          </p>
          <div className='table-container'>
            <table className='table'>
              <thead>
                <tr>
                  <th>Note</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Recorder</th>
                </tr>
              </thead>
              <tbody>
                { notesData() }
              </tbody>
            </table>
          </div>
        </div>
      </section>
  }

  const checkUnstartJob = () => {
    setUnStartClass(unStartClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
  }

  const unstartJob = async () => {
    const result = await ApiClient.request(`/api/jobs/${job.jobid}/unstart_job`);
    let msg = 'Job at ' + address + ' unstarted successfully';
    if(!result.success){
      msg = 'Job at ' + address + ' unstart FAILED, call Nat/Roger';
    }
    history.push(`/runsheet/${msg}`);
  }


  const checkUnreportJob = () => {
    setUnReportClass(unReportClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
  }

  const unreportJob = async () => {
    const result = await ApiClient.request(`/api/jobs/${job.jobid}/unreport_job`);
    let msg = 'Job at ' + address + ' unreported successfully';
    if(!result.success){
      msg = 'Job at ' + address + ' unreported FAILED, call Nat/Roger';
    }
    history.push(`/runsheet/${msg}`);
  }

  const checkCancelJob = () => {
    setCancelClass(cancelClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    setShowCancelForm(true);
  }

  const handleCancelJob = async (data) => {
    console.log('Data', data);
    setShowCancelForm(false);
    setCancelClass(cancelClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    //    const result = await ApiClient.request(`/api/jobs/${job.jobid}/cancel_job`);
    //    history.push(`/runsheet/${result.msg}`);

    //let updatedData = { ...data, hrid: state_crew.crew_hrid, jobid: job.jobid };

    const result = await ApiClient.post(`/api/jobs/cancel_job`, data);
    history.push(`/runsheet/${result.msg}`);
  }

  const cancelCancelRequest = async () => {
    setShowCancelForm(false);
    setCancelClass(cancelClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
  }



  const checkEavesPlusFive = () => {
    setEavesPlusFiveClass(eavesPlusFiveClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    setShowEavesPlusFiveForm(true);
  }

  const handleEavesPlusFive = async (data) => {
    console.log('Data', data);
    setShowEavesPlusFiveForm(false);
    setEavesPlusFiveClass(eavesPlusFiveClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    //    const result = await ApiClient.request(`/api/jobs/${job.jobid}/cancel_job`);
    //    history.push(`/runsheet/${result.msg}`);

    let updatedData = { ...data, hrid: state_crew.crew_hrid };

    const result = await ApiClient.post(`/api/jobs/eaves_plus_five_job`, updatedData);
    history.push(`/runsheet/${result.msg}`);
  }

  const cancelEavesPlusFive = async () => {
    setShowEavesPlusFiveForm(false);
    setEavesPlusFiveClass(eavesPlusFiveClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
  }



  const checkRescheduleJob = () => {
    setRescheduleClass(rescheduleClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    setShowRescheduleForm(true);
  }

  const handleRescheduleJob = async (data) => {
    console.log('Data', data);
    setShowRescheduleForm(false);
    setRescheduleClass(rescheduleClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
    //const result = await ApiClient.request(`/api/jobs/${job.jobid}/reschedule_job`);
    // let msg = 'Job at ' + address + ' rescheduled successfully';
    // if(!result.success){
    //   msg = 'Job at ' + address + ' reschedule FAILED, call Nat/Roger';
    // }
    const result = await ApiClient.post(`/api/jobs/reschedule_job`, data);
    history.push(`/runsheet/${result.msg}`);
  }

  const cancelRescheduleRequest = async () => {
    setShowRescheduleForm(false);
    setRescheduleClass(rescheduleClass==='button is-danger' ? 'button is-danger is-hidden' : 'button is-danger');
  }

  const showJobPics = () => {
    console.log("In Job Pics");
    const job_pic_images = jobPics.map(pic => (
      <div className='column is-one-third'>
        <div className="card">
          <div className="card-image">
            <figure className="image is-4by3">
            <img src={pic.image_url} alt="Job Image" height='500' width="300"/>
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{ pic.kind }</p>
                <p className="title is-4">{ pic.title }</p>
                <hr/>
                <time datetime="2016-1-1">{ pic.created_at }</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))

    return(
      <div className='columns is-multiline'>
        { job_pic_images }
      </div>
    )
  }

  const showPropertyPics = () => {
    console.log("In Property Pics");
    const property_pic_images = propertyPics.map(pic => (
      <div className='column is-one-third'>
        <div className="card">
          <div className="card-image">
            <figure className="image is-4by3">
            <img src={pic.image_url} alt="Property Image" height='500' width="300"/>
            </figure>
          </div>
          <div className="card-content">
            <div className="media">
              <div className="media-content">
                <p className="title is-4">{ pic.kind }</p>
                <p className="title is-4">{ pic.title }</p>
                <hr/>
                <time datetime="2016-1-1">{ pic.created_at }</time>
              </div>
            </div>
          </div>
        </div>
      </div>
    ))

    return(
      <div className='columns is-multiline'>
        { property_pic_images }
      </div>
    )
  }

  return (
    <Layout>
      <div className='section'>
        <div className="container content">
          <nav className='breadcrumb'>
            <ul>
              <li><Link to="/">Home</Link></li>
              <li>
                <a href="/menu_items/crew_menu">
                  Back to Crew Menu
                </a>
              </li>
            <li><Link to="/runsheet">Runsheet</Link></li>
              <li className='is-active'><Link to={`/job-details/${job && job.jobid}`}>Job Details</Link></li>
            </ul>
          </nav>
          <h2 className='title has-text-centered'>{crew}</h2>
          <hr/>
          <h3 className='subtitle is-4'>{client}</h3>
          <h3 className='subtitle is-4'>{address}</h3>
          <h3 className='subtitle is-4'>Ph:{phone}</h3>
          <h3 className='subtitle is-4'>Cell:{cellPhone}</h3>
              { job && job.prodcomm === '**Emergency**' ? <h3 className='subtitle is-4 has-text-danger'>**Emergency**</h3> : null }
          { job && holdingCell ? <h3 className='subtitle is-4 has-text-link'>**Holding Cell**</h3> : null }
          <hr/>
            { notes && showNotes() }
          <hr/>
            {property &&
            <u>
              <a className="navbar-item is-fullwidth" href={`/property_images?property_id=${property.jobinfoid}&source=react_job_details&delete_source=react_job_details&jobid=${job.jobid}`}>
                Add Property Image
              </a>
            </u>}

            { property && propertyPics && showPropertyPics() }
          <hr/>
            {job &&
            <u>
              <a className="navbar-item is-fullwidth" href={`/job_images?jobid=${job.jobid}&source=react_job_details`}>
                Add Job Image
              </a>
            </u>}

            { job && jobPics && showJobPics() }
            <hr/>
            <div className="columns is-multiline has-background-primary">
            <div className="column">
              <p className="control">
                { job && job.reportstime!==null && job.reportstime!=='99:99:99' ?
                <button className="button is-link" onClick={ checkUnstartJob }>
                  Unstart Job
                </button> :
                <button className="button is-link" disabled onClick={ checkUnstartJob }>
                  Unstart Job
                </button>
                }

              </p><br/>
              <p className="control">
                <button className={unStartClass} onClick={ unstartJob }>
                  Confirm Unstart Job
                </button>
              </p>
            </div>
            <div className="column">
              <p className="control">
                { job && job.datebi !== null ?
                  <button className="button is-link" onClick={ checkUnreportJob }>
                    Unreport Job
                  </button> :
                  <button className="button is-link" disabled onClick={ checkUnreportJob }>
                    Unreport Job
                  </button>
                }
              </p><br/>
              <p className="control">
                <button className={unReportClass} onClick={ unreportJob }>
                  Confirm Unreport Job
                </button>
              </p>
            </div>
            <div className="column">
              <p className="control">
                { job && job.datebi === null ?
                  <button className="button is-link" onClick={ checkCancelJob }>
                    Cancel Job
                  </button> :
                  <button className="button is-link" disabled onClick={ checkCancelJob }>
                    Cancel Job
                  </button>                }
              </p><br/>
              <p className="control">
                <button className={cancelClass} onClick={ cancelCancelRequest }>
                  Cancel Cancel Request
                </button>
              </p>
            </div>
            <div className="column">
              <p className="control">
                { job && job.datebi === null ?
                  <button className="button is-link" onClick={ checkRescheduleJob }>
                    Reschedule Job
                  </button> :
                  <button className="button is-link" disabled onClick={ checkRescheduleJob }>
                    Reschedule Job
                  </button>
                }
              </p><br/>
              <p className="control">
                <button className={rescheduleClass} onClick={ cancelRescheduleRequest }>
                  Cancel Reschedule Request
                </button>
              </p>
            </div>
            <div className="column">
              <p className="control">
                { job && job.datebi === null ?
                  <button className="button is-link" onClick={ checkEavesPlusFive }>
                    Eaves +5 days
                  </button> :
                  <button className="button is-link" disabled onClick={ checkEavesPlusFive }>
                    Eaves +5 days
                  </button>
                }
              </p><br/>
              <p className="control">
                <button className={eavesPlusFiveClass} onClick={ cancelEavesPlusFive }>
                  Cancel Eaves +5
                </button>
              </p>
            </div>
          </div>
          { showCancelForm && <CancelJobForm handleCancelJob={handleCancelJob} jobid={job.jobid} />}
          { showRescheduleForm && <RescheduleJobForm handleRescheduleJob={handleRescheduleJob} jobid={job.jobid} />}
          { showEavesPlusFiveForm && <EavesPlusFiveForm handleEavesPlusFive={handleEavesPlusFive} jobid={job.jobid} />}
          <hr/>
          <br/>

          <form className='form' onSubmit={handleSubmit(startJob)}>
            <div className='columns multiline'>
              <div className='column is-half'>
                <div className='field'>
                  <div className='control'>
                    <label className='label' htmlFor='start_label'>Start Time:</label>
                    <div className='select' id='start-label'>
                      <select name='startHourPrior' onChange={onChange} ref={register} >
                        { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                      </select>
                    </div> :
                    <div className='select' >
                      <select name='startMinutePrior' onChange={onChange} ref={register} >
                        { mins.map((min)=>(<option key={min} value={min}>{min}</option>)) }
                      </select>
                    </div>
                  </div>
                  <ErrorMsg msg={errorMsgs.startHourPriorCannotBeBlank} num={ Object.keys(errorMsgs).length }/>
                  <ErrorMsg msg={errorMsgs.startMinutePriorCannotBeBlank} num={ Object.keys(errorMsgs).length }/>
                </div>
               <div className='field'>
                 <div className='control'>
                   <label className='label' htmlFor='estmins_label'>Estimated Mins:</label>
                   <input id='estmins_label' size='10' className='input' type="text" name="estMins" ref={ register } />
                 </div>
                 <ErrorMsg msg={errorMsgs.estMinsEntered} num={ Object.keys(errorMsgs).length }/>
                 <ErrorMsg msg={errorMsgs.estMins} num={ Object.keys(errorMsgs).length }/>
                 <ErrorMsg msg={errorMsgs.estMinsGreaterThanZero} num={ Object.keys(errorMsgs).length }/>
               </div>
               <div className='field'>
                 <div className='control'>
                   <label className='label' htmlFor='preJobNotes'>Pre Job Note:</label>
                   <textarea
                     className='textarea'
                     rows='3'
                     name='preJobNote'
                     placeholder='Enter pre-job notes(optional)...'
                     ref={ register }
                     defaultValue={ preJobNote }
                   />
                 </div>
               </div>
              </div>
            </div>

            <button name='start_job' type='submit' className='button is-link is-large is-rounded is-fullwidth has-text-weight-bold'>Start Job</button>
          </form><br/>

          <hr/>

          <form className='form' onSubmit={handleSubmit(returnJob)}>
            <div className='columns multiline'>
              <div className='column is-3'>
                <div className='field'>
                  <div className='control'>
                    <label className='label' htmlFor='start_label'>Start Time:</label>
                    <div className='select is-small' id='start-label'>
                      <select name='startHour' onChange={onChange} ref={register} >
                        { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                      </select>
                    </div> :
                    <div className='select is-small' >
                      <select name='startMinute' onChange={onChange} ref={register} >
                        { mins.map((min)=>(<option key={min} value={min}>{pad && min!=='' ? String(min).padStart(pad, "0") : min}</option>)) }
                      </select>
                    </div>
                  </div>
                  <ErrorMsg msg={errorMsgs.startHourCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                  <ErrorMsg msg={errorMsgs.startMinuteCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div>


              <div className='column is-3'>
                <div className='field'>
                  <div className='control'>
                    <label className='label' htmlFor='finish_label'>Finish Time:</label>
                    <div className='select is-small' id='finish_label'>
                      <select name='finishHour' onChange={onChange} ref={register} >
                        { hours.map((hour)=>(<option key={hour} value={hour}>{hour}</option>)) }
                      </select>
                    </div> :
                    <div className='select is-small' >
                      <select name='finishMinute' onChange={onChange} ref={register} >
                        { mins.map((min)=>(<option key={min} value={min}>{pad && min!=='' ? String(min).padStart(pad, "0") : min}</option>)) }
                      </select>
                    </div>
                  </div>
                  <ErrorMsg msg={errorMsgs.finishHourCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                  <ErrorMsg msg={errorMsgs.finishMinuteCannotBeBlank} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div>

              <div className='column is-2 is-5-mobile'>
                <div className='field'>
                  <div className='control'>
                    <label className="label" htmlFor='totmins'>Total Minutes</label>
                    <input id='totmins' size='10' className='input is-small ' type="text" name="totMins" value={workMinutes} ref={ register } readOnly />
                  </div>
                </div>
                <ErrorMsg msg={errorMsgs.workMinutesGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
              </div>
            </div>

            <div className='columns multiline'>
              <div className='column'>
                <div className='field'>
                  <div className='control'>
                    <label className="checkbox">
                      <input name='w1Check' type="checkbox" checked={state.w1Checked} ref={ register } onChange={onChange} />
                      W1
                      { state.w1Checked==='checked' ?
                      <input
                        type='text'
                        className='input'
                        name='w1Text'
                        ref={ register }
                        onChange={ ()=>createjobdesc(false) }
                      /> :
                      <input
                        type='text'
                        className='input'
                        name='w1Text'
                        ref={ register }
                        onChange={ ()=>createjobdesc(false) }
                        disabled
                      />
                    }
                    </label>
                  </div>
                  <ErrorMsg msg={errorMsgs.w1TextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                  <ErrorMsg msg={errorMsgs.w1TextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div>

              <div className='column'>
                <div className='field'>
                  <div className='control'>
                    <label className="checkbox">
                      <input name='w2Check' type="checkbox" checked={state.w2Checked} ref={ register } onChange={onChange} />
                      W2
                      { state.w2Checked==='checked' ?
                      <input
                        type='text'
                        className='input'
                        name='w2Text'
                        ref={ register }
                        onChange={ ()=>createjobdesc(false) }
                      /> :
                      <input
                        type='text'
                        className='input'
                        name='w2Text'
                        ref={ register }
                        onChange={ ()=>createjobdesc(false) }
                        disabled
                      /> }
                    </label>
                  </div>
                  <ErrorMsg msg={errorMsgs.w2TextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                  <ErrorMsg msg={errorMsgs.w2TextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div>

              <div className='column'>
                <div className='field'>
                  <div className='control'>
                    <label className="checkbox">
                      <input name='w3Check' type="checkbox" checked={state.w3Checked} ref={ register } onChange={onChange} />
                      W3
                      { state.w3Checked==='checked' ?
                      <input
                        type='text'
                        className='input'
                        name='w3Text'
                        ref={ register }
                        onKeyUp={ ()=>createjobdesc(false) }
                      /> :
                      <input
                        type='text'
                        className='input'
                        name='w3Text'
                        ref={ register }
                        onChange={ ()=>createjobdesc(false) }
                        disabled
                        /> }
                    </label>
                  </div>
                  <ErrorMsg msg={errorMsgs.w3TextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                  <ErrorMsg msg={errorMsgs.w3TextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div>

              <div className='column'>
                <div className='field'>
                  <div className='control'>
                    <label className="checkbox">
                      <input name='w4Check' type="checkbox" checked={state.w4Checked} ref={ register } onChange={onChange} />
                      W4
                      { state.w4Checked==='checked' ?
                      <input
                        type='text'
                        className='input'
                        name='w4Text'
                        ref={ register }
                        onChange={ ()=>createjobdesc(false) }
                        /> :
                      <input
                        type='text'
                        className='input'
                        name='w4Text'
                        ref={ register }
                        onChange={ ()=>createjobdesc(false) }
                        disabled
                        /> }
                    </label>
                  </div>
                  <ErrorMsg msg={errorMsgs.w4TextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                  <ErrorMsg msg={errorMsgs.w4TextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div>
            </div>

            <div className='columns multiline'>
              <div className='column is-one-quarter-tablet'>
                <div className='field'>
                  <div className='control'>
                    <label className="checkbox">
                      <input name='ehCheck' type="checkbox" checked={state.ehChecked} ref={ register } onChange={onChange} />
                        EH
                        { state.ehChecked==='checked' ?
                        <input
                          type='text'
                          className='input'
                          name='ehText'
                          ref={ register }
                          onChange={ ()=>createjobdesc(false) }
                          /> :
                        <input
                          type='text'
                          className='input'
                          name='ehText'
                          ref={ register }
                          onChange={ ()=>createjobdesc(false) }
                          disabled
                          /> }
                    </label>
                  </div>
                  <ErrorMsg msg={errorMsgs.ehTextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                  <ErrorMsg msg={errorMsgs.ehTextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div>

              <div className='column is-one-quarter-tablet'>
                <div className='field'>
                  <div className='control'>
                    <label className="checkbox">
                      <input name='egCheck' type="checkbox" checked={state.egChecked} ref={ register } onChange={onChange} />
                      EG
                      { state.egChecked==='checked' ?
                      <input
                        type='text'
                        className='input'
                        name='egText'
                        ref={ register }
                        onChange={ ()=>createjobdesc(false) }
                        /> :
                      <input
                        type='text'
                        className='input'
                        name='egText'
                        ref={ register }
                        onChange={ ()=>createjobdesc(false) }
                        disabled
                        /> }
                    </label>
                  </div>
                  <ErrorMsg msg={errorMsgs.egTextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                  <ErrorMsg msg={errorMsgs.egTextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div>

              <div className='column is-half-tablet'>
                <div className='field'>
                  <div className='control'>
                    <label className="checkbox">
                      <input name='cagesCheck' type="checkbox" checked={state.cagesChecked} ref={ register } onChange={onChange} />
                      Cages
                      { state.cagesChecked ?
                      <div className='columns'>
                        <div className='column is-two-fifths'>
                          <div className='select' >
                            <select name='cagesSelect' onChange={ ()=>createjobdesc(false) } ref={ register }>
                              <option value="as Reqd">as Reqd</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                        <div className='column is-two-fifths'>
                          <input
                            type='text'
                            className='input'
                            name='cagesText'
                            ref={ register }
                          />
                        </div>
                      </div> :
                      <div className='columns'>
                        <div className='column is-two-fifths'>
                          <div className='select' >
                            <select name='cagesSelect' onChange={ ()=>createjobdesc(false) } ref={ register } disabled>
                              <option value="as Reqd">as Reqd</option>
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                            </select>
                          </div>
                        </div>
                        <div className='column is-two-fifths'>
                          <input
                            type='text'
                            className='input'
                            name='cagesText'
                            ref={ register }
                            disabled
                          />
                        </div>
                      </div>
                    }
                    </label>
                  </div>
                </div>
              </div>
            </div>

            <div className='field'>
              <div className='control'>
                <label className="checkbox">
                  <input name='customCheck' type="checkbox" checked={state.customChecked} ref={ register } onChange={onChange} />
                  Custom Price
                  { state.customChecked ?
                  <input
                    type='text'
                    className='input is-fullwidth'
                    name='customText'
                    ref={ register }
                    onChange={ ()=>createjobdesc(false) }
                  /> :
                  <input
                    type='text'
                    className='input is-fullwidth'
                    name='customText'
                    ref={ register }
                    onChange={ ()=>createjobdesc(false) }
                    disabled
                  />
                  }
                </label>
              </div>
              <ErrorMsg msg={errorMsgs.customTextMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
              <ErrorMsg msg={errorMsgs.customTextMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
            </div>

            <div className='field'>
              <div className='control'>
                { state.customChecked ?
                <textarea
                  className='textarea'
                  rows='2'
                  name='customDesc'
                  ref={ register }
                  onKeyUp={ ()=>createjobdesc(false) }
                /> :
                <textarea
                  className='textarea'
                  rows='2'
                  name='customDesc'
                  ref={ register }
                  onClick={ ()=>createjobdesc(false) }
                  disabled
                />
                }
              </div>
            </div><br/>

            <div className='field'>
              <div className='control'>
                <label className="label" forhtml='jobdesc_input'>Job Description</label>
                  <textarea
                    className='textarea'
                    rows='4'
                    id='jobdesc_input'
                    name='jobDesc'
                    ref={ register }
                    readOnly
                  />
              </div>
            </div>

            <div className='columns'>
              <div className='column is-2 is-5-mobile'>
                <div className='field'>
                  <div className='control'>
                    <label className="label" htmlFor='price_label'>Price</label>
                    <input className='input' type='text' name='price' id='price_label' ref={ register } readOnly />
                  </div>
                </div>
              </div>
              <div className='column is-2 is-5-mobile'>
                <div className='field'>
                  <div className='control'>
                    <label className="label" htmlFor='hst_label'>HST</label>
                    <input className='input' type='text' name='hst' id='hst_label' ref={ register } readOnly />
                  </div>
                </div>
              </div>
              <div className='column is-2 is-5-mobile'>
                <div className='field'>
                  <div className='control'>
                    <label className="label" htmlFor='totalPrice_label'>Total</label>
                    <input className='input' type='text' name='totalPrice' id='totalPrice_label' ref={ register } readOnly />
                  </div>
                </div>
              </div>
            </div>

            <div className='field'>
              <div className='control'>
                <label className='label' htmlFor='postJobNotes'>Post Job Notes:</label>
                <textarea
                  className='textarea'
                  rows='3'
                  name='postJobNote'
                  placeholder='Enter post-job notes(optional)...'
                  ref={ register }
                  defaultValue = { postJobNote }
                />
              </div>
            </div>

            <hr/>
            <div className='columns'>
              <div className='column is-4 is-5-mobile'>
                <div className='field'>
                  <div className='control'>
                    <label className="label" htmlFor='recStatus_label'>Rec Status</label>
                    <div className='select' id='recStatus_label'>
                      <select name='recStatus' onChange={editRecStatus} ref={ register }>
                        <option value="None Selected">None Selected</option>
                        <option value="Client Did Not Pay">Client Did Not Pay</option>
                        <option value="Client Paid By Cheque">Client Paid By Cheque</option>
                        <option value="Client Paid By Cash">Client Paid By Cash</option>
                      </select>
                    </div>
                    <ErrorMsg msg={errorMsgs.recStatusMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
                  </div>
                </div>
                <div className={ showCash }>
                  <div className='field'>
                    <div className='control'>
                    <label className="checkbox">
                      <input name='sendCashReceipt' type="checkbox" defaultChecked='checked' ref={ register } />
                      Send Paid Cash Receipt{ email ? '[' + email + ']' : null }
                    </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className={showCashAmount}>
                <div className='field'>
                  <div className='control'>
                    <label className="label" htmlFor='cash_amount_label'>Cash Amount</label>
                    <input className='input' id='cash_amount_label' type='text' name='cashAmount' ref={ register }  />
                  </div>
                  <ErrorMsg msg={errorMsgs.cashAmountMustBeEntered} num={ Object.keys(errorMsgs).length-1 }/>
                  <ErrorMsg msg={errorMsgs.cashAmountMustBeANumber} num={ Object.keys(errorMsgs).length-1 }/>
                  <ErrorMsg msg={errorMsgs.cashAmountMustBeGreaterThanZero} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div>


              <div className={chqNumClass}>
                <div className='field'>
                  <div className='control'>
                    <label className="label" htmlFor='chqnum_label'>Chq Num</label>
                    <input className='input' id='chqnum_label' type='text' name='chqNum' ref={ register }  />
                  </div>
                  <ErrorMsg msg={errorMsgs.chqNumberMustBeEntered} num={ Object.keys(errorMsgs).length-1 }/>
                </div>
              </div>
            </div>

            <div className='field'>
              <div className='control'>
                <label className="label" htmlFor='lawnSign_label'>Lawn Sign</label>
                <div className='select' id='lawnSign_label'>
                  <select name='lawnSign' ref={ register }>
                    <option value='None Selected'>None Selected</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                  </select>
                </div>
                <ErrorMsg msg={errorMsgs.lawnSignMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
              </div>
            </div><br/>

            <div className='field'>
              <div className='control'>
                <label className="label" htmlFor='clientHome_label'>Client Home</label>
                <div className='select' id='clientHome_label'>
                  <select name='clientHome' ref={ register }>
                    <option value='None Selected'>None Selected</option>
                    <option value='Yes'>Yes</option>
                    <option value='No'>No</option>
                  </select>
                </div>
                <ErrorMsg msg={errorMsgs.clientHomeMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
              </div>
            </div><br/>

            <div className='field'>
              <div className='control'>
                <label className="label" htmlFor='nextDest_label'>Next Destination</label>
                <div className='select' id='nextDest_label'>
                  <select name='nextDest' ref={ register }>
                    { nextDestOptions() }
                  </select>
                </div>
                <ErrorMsg msg={errorMsgs.nextDestinationMustBeSelected} num={ Object.keys(errorMsgs).length-1 }/>
              </div>
            </div><br/>
            { Object.keys(errorMsgs).length-1 > 0 ?
              <ErrorMsg msg={errorMsgs.totalErrors} num={ Object.keys(errorMsgs).length-1 } /> : null }



            { showSubmitButton ?
                <button name='return_job' type='submit' className='button is-link is-large is-rounded is-fullwidth has-text-weight-bold'>{buttonText}</button> :
                <button name='return_job' type='submit' className='button is-link is-large is-rounded is-fullwidth has-text-weight-bold' disabled>{buttonText}</button>
            }
          </form>
          { pastJobs && <PastJobs pastJobs={pastJobs} /> }
        </div>
      </div>
    </Layout>
  );
};

export default JobDetails;

//String(min).padStart(pad, "0")


// <label className="label" htmlFor='pics_label'>Post Job Images</label>
// <input
//   className='input'
//   id='pics_label'
//   type='file'
//   name='postJobImages'
//   ref={ register }
//   multiple
// />
