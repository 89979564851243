import React, { useState, useEffect } from 'react';
//import Layout from "../../../layout/Layout";
import { Link, Redirect } from "react-router-dom";

import Loader from "../../../../base/layout/Loader";
import { useStateReducer } from "../../../../base/context/authContext";
import ApiClient from "../../../../base/api/client";
import { formatCurrencyZero, totalBurden, royaltyCalculator } from "../../../helpers/helper";

const Report = ({ showDisplay }) => {
  const [{ mgr }] = useStateReducer();

  const [showLoader, setShowLoader] = useState(true);

  const [jobsPaidTot, setJobsPaidTot] = useState(0);
  const [jobsRecTot, setJobsRecTot] = useState(0);
  const [jobsPaidTot1, setJobsPaidTot1] = useState(0);
  const [jobsRecTot1, setJobsRecTot1] = useState(0);
  const [jobsPaidTot2, setJobsPaidTot2] = useState(0);
  const [jobsRecTot2, setJobsRecTot2] = useState(0);

  const [oddJobsPaidTot, setOddJobsPaidTot] = useState(0);
  const [oddJobsMatPaidTot, setOddJobsMatPaidTot] = useState(0);
  const [oddJobsPaidTot1, setOddJobsPaidTot1] = useState(0);
  const [oddJobsMatPaidTot1, setOddJobsMatPaidTot1] = useState(0);
  const [oddJobsPaidTot2, setOddJobsPaidTot2] = useState(0);
  const [oddJobsMatPaidTot2, setOddJobsMatPaidTot2] = useState(0);



  const [oddJobsRecTot, setOddJobsRecTot] = useState(0);
  const [oddJobsMatRecTot, setOddJobsMatRecTot] = useState(0);
  const [oddJobsRecTot1, setOddJobsRecTot1] = useState(0);
  const [oddJobsMatRecTot1, setOddJobsMatRecTot1] = useState(0);
  const [oddJobsRecTot2, setOddJobsRecTot2] = useState(0);
  const [oddJobsMatRecTot2, setOddJobsMatRecTot2] = useState(0);

  const [paintPaidTot, setPaintPaidTot] = useState(0);
  const [paintRecTot, setPaintRecTot] = useState(0);
  const [paintPaidTot1, setPaintPaidTot1] = useState(0);
  const [paintRecTot1, setPaintRecTot1] = useState(0);
  const [paintPaidTot2, setPaintPaidTot2] = useState(0);
  const [paintRecTot2, setPaintRecTot2] = useState(0);

  const[totalEarned, setTotalEarned] = useState(0);
  const[totalReceivable, setTotalReceivable] = useState(0);
  const[totalEarnedAndReceivable, setTotalEarnedAndReceivable] = useState(0);

  const[afterRoyalty, setAfterRoyalty] = useState(0);
  const[afterRoyalty1, setAfterRoyalty1] = useState(0);

  const [wss, setWss] = useState([]);
  const [mts, setMts] = useState([]);
  const[personalMtsTotal, setPersonalMtsTotal] = useState(0);
  const[personalWssTotal, setPersonalWssTotal] = useState(0);
  const[estsSignsVisitTotal, setEstsSignsVisitTotal] = useState(0);
  const[estsSignsSalesTotal, setEstsSignsSalesTotal] = useState(0);

  const[expensesTotal, setExpensesTotal] = useState(0);
  const[payouts, setPayouts] = useState([]);
  const[payoutsTotal, setPayoutsTotal] = useState(0);


  const fetchReport = async () => {
    setShowLoader(true);
    let myParams = { crewname: mgr.name, hrid: mgr.hrid, show_display: showDisplay };
    let qs = new URLSearchParams(myParams).toString();
    let result = await ApiClient.request(`/api/partners/report_summary?${qs}`);
    console.log('REEESULT', result);

    setJobsPaidTot(result.jobs_paid_tot ? result.jobs_paid_tot : 0);
    setJobsRecTot(result.jobs_rec_tot ? result.jobs_rec_tot : 0);

    setJobsPaidTot1(result.jobs_paid_tot1 ? result.jobs_paid_tot1 : 0);
    setJobsRecTot1(result.jobs_rec_tot1 ? result.jobs_rec_tot1 : 0);

    setOddJobsPaidTot(result.odd_jobs_paid_tot[0].price ? result.odd_jobs_paid_tot[0].price : 0);
    setOddJobsMatPaidTot(result.odd_jobs_paid_tot[0].mat_price ? result.odd_jobs_paid_tot[0].mat_price : 0);

    setOddJobsPaidTot1(result.odd_jobs_paid_tot1[0].price ? result.odd_jobs_paid_tot1[0].price : 0);
    setOddJobsMatPaidTot1(result.odd_jobs_paid_tot1[0].mat_price ? result.odd_jobs_paid_tot1[0].mat_price : 0);

    setOddJobsRecTot(result.odd_jobs_rec_tot[0].price ? result.odd_jobs_rec_tot[0].price : 0);
    setOddJobsMatRecTot(result.odd_jobs_rec_tot[0].mat_price ? result.odd_jobs_rec_tot[0].mat_price : 0);

    setOddJobsRecTot1(result.odd_jobs_rec_tot1[0].price ? result.odd_jobs_rec_tot1[0].price : 0);
    setOddJobsMatRecTot1(result.odd_jobs_rec_tot1[0].mat_price ? result.odd_jobs_rec_tot1[0].mat_price : 0);

    setPaintPaidTot(result.paint_paid_tot[0].price ? result.paint_paid_tot[0].price : 0);
    setPaintRecTot(result.paint_rec_tot[0].price ? result.paint_rec_tot[0].price : 0);

    setPaintPaidTot1(result.paint_paid_tot1[0].price ? result.paint_paid_tot1[0].price : 0);
    setPaintRecTot1(result.paint_rec_tot1[0].price ? result.paint_rec_tot1[0].price : 0);


    let expense_list;
    let payout_list;
    let list_total;
    let personal_list_total;
    let ests_signs;
    if (showDisplay === 'Report 2023'){
      expense_list = 'expense_list_total_2023';
      payout_list = 'payout_list_2023';
      list_total = 'list_total_2023';
      personal_list_total = 'personal_list_total_2023';
      ests_signs = 'ests_signs_2023';
    }else if (showDisplay === 'Report 2024'){
      expense_list = 'expense_list_total_2024';
      payout_list = 'payout_list_2024';
      list_total = 'list_total_2024';
      personal_list_total = 'personal_list_total_2024';
      ests_signs = 'ests_signs_2024';
    }

    myParams = { list: expense_list, hrid: mgr.hrid };
    qs = new URLSearchParams(myParams);
    result = await ApiClient.request(`/api/partners?${qs}`);
    setExpensesTotal(result);

    myParams = { list: payout_list, hrid: mgr.hrid };
    qs = new URLSearchParams(myParams);
    result = await ApiClient.request(`/api/partners?${qs}`);
    setPayouts(result);

    let totalNet = 0;
    let totalCPP = 0;
    let totalEmpCPP = 0;
    let totalEI = 0;
    let totalEmpEI = 0;
    let totalTax = 0;
    let totalHST = 0;
    let totalGross = 0;
    let totalEHT = 0;
    let totalWSIB = 0;

    result.forEach((payout) => {
      totalNet += parseFloat(payout.net);
      totalCPP += parseFloat(payout.wkr_cpp);
      totalEmpCPP += parseFloat(payout.emp_cpp);
      totalEI += parseFloat(payout.wkr_ei);
      totalEmpEI += parseFloat(payout.emp_ei);
      totalTax += parseFloat(payout.wkr_tax);
      totalHST += parseFloat(payout.hst);
      totalGross += parseFloat(payout.gross);
      totalEHT += parseFloat(payout.eht);
      totalWSIB += parseFloat(payout.wsib);
    })
    setPayoutsTotal(totalGross + totalEHT + totalWSIB + totalEmpEI + totalEmpCPP);




    myParams = { hrid: mgr.hrid, list: list_total };
    qs = new URLSearchParams(myParams).toString();
    result = await ApiClient.request(`/api/workschedules?${qs}`);
    setWss(result);


    myParams = { hrid: mgr.hrid, list: list_total };
    qs = new URLSearchParams(myParams).toString();
    result = await ApiClient.request(`/api/mgt_tasks?${qs}`);
    setMts(result);


    myParams = { hrid: mgr.hrid, list: personal_list_total};
    qs = new URLSearchParams(myParams).toString();
    result = await ApiClient.request(`/api/mgt_tasks/?${qs}`);
    setPersonalMtsTotal(result);

    myParams = { hrid: mgr.hrid, list: personal_list_total};
    qs = new URLSearchParams(myParams).toString();
    result = await ApiClient.request(`/api/workschedules/?${qs}`);
    setPersonalWssTotal(result);

    myParams = { list: ests_signs, hrid: mgr.hrid };
    qs = new URLSearchParams(myParams).toString();
    const estsSigns = await ApiClient.request(`/api/messages?${qs}`);
    let totalVisitPay = 0;
    let totalSalesPay = 0;
    estsSigns.forEach((est) =>{
      totalVisitPay += parseFloat(est.visit_pay);
      totalSalesPay += parseFloat(est.sales_pay);
    })
    setEstsSignsVisitTotal(totalVisitPay);
    setEstsSignsSalesTotal(totalSalesPay);

    setShowLoader(false);
  }



  useEffect(() => {
    if(mgr){
      console.log('MGR', mgr);
      let tempAfterRoyalty = 0;
      let tempAfterRoyalty1 = 0;
      if(showDisplay === 'Report 2023'){
        tempAfterRoyalty = 1 - parseFloat(mgr.royalty);
        tempAfterRoyalty1 = 1 - parseFloat(mgr.royalty1);
      }else if(showDisplay === 'Report 2024'){
        tempAfterRoyalty = 1 - parseFloat(mgr.royalty);
        tempAfterRoyalty1 = 1 - parseFloat(mgr.royalty1);
      }
      setAfterRoyalty(tempAfterRoyalty);
      setAfterRoyalty1(tempAfterRoyalty1);
      fetchReport();
    }
  }, [mgr, showDisplay])

  useEffect(() => {
    let tempEarned = (parseFloat(jobsPaidTot) * afterRoyalty) + (oddJobsPaidTot - oddJobsMatPaidTot) * afterRoyalty + (paintPaidTot * afterRoyalty);
    let tempEarned1 = (parseFloat(jobsPaidTot1) * afterRoyalty1) + (oddJobsPaidTot1 - oddJobsMatPaidTot1) * afterRoyalty1 + (paintPaidTot1 * afterRoyalty1);
    setTotalEarned(tempEarned + tempEarned1);
    let tempReceivable = (parseFloat(jobsRecTot) * afterRoyalty) + (parseFloat(oddJobsRecTot) - parseFloat(oddJobsMatRecTot)) * afterRoyalty + (parseFloat(paintRecTot) * afterRoyalty);
    let tempReceivable1 = (parseFloat(jobsRecTot1) * afterRoyalty1) + (parseFloat(oddJobsRecTot1) - parseFloat(oddJobsMatRecTot1)) * afterRoyalty1 + (parseFloat(paintRecTot1) * afterRoyalty1);
    setTotalReceivable(tempReceivable + tempReceivable1);
    setTotalEarnedAndReceivable(tempEarned + tempEarned1 + tempReceivable + tempReceivable1);
  }, [afterRoyalty, afterRoyalty1, jobsPaidTot, jobsPaidTot1, oddJobsMatPaidTot, oddJobsMatPaidTot1, paintPaidTot, paintPaidTot1, jobsRecTot, jobsRecTot1, oddJobsRecTot, oddJobsRecTot1, oddJobsMatRecTot, oddJobsMatRecTot1, paintRecTot, paintRecTot1])

  const tableRevenueData = () =>
     (
      <tbody>
        <tr>
          <td>Window Revenue - Paid</td>
          <td>{ formatCurrencyZero(parseFloat(jobsPaidTot) + parseFloat(jobsPaidTot1)) }</td>
          <td>{ formatCurrencyZero(0) }</td>
          <td>{ formatCurrencyZero(parseFloat(jobsPaidTot) + parseFloat(jobsPaidTot1)) }</td>
          <td>{ formatCurrencyZero((jobsPaidTot * parseFloat(mgr.royalty)) + ( jobsPaidTot1 * parseFloat(mgr.royalty1))) }</td>
          <td>{ formatCurrencyZero((jobsPaidTot * afterRoyalty) + (jobsPaidTot1 * afterRoyalty1)) }</td>
        </tr>
        <tr>
          <td>Odd Job Revenue - Paid</td>
          <td>{ formatCurrencyZero(parseFloat(oddJobsPaidTot) + parseFloat(oddJobsPaidTot1)) }</td>
          <td>{ formatCurrencyZero(parseFloat(oddJobsMatPaidTot) + parseFloat(oddJobsMatPaidTot1)) }</td>
          <td>{ formatCurrencyZero((parseFloat(oddJobsPaidTot) - parseFloat(oddJobsMatPaidTot)) + (parseFloat(oddJobsPaidTot1) - parseFloat(oddJobsMatPaidTot1))) }</td>
          <td>{ formatCurrencyZero(((parseFloat(oddJobsPaidTot) - parseFloat(oddJobsMatPaidTot)) * parseFloat(mgr.royalty)) + ((parseFloat(oddJobsPaidTot1) - parseFloat(oddJobsMatPaidTot1)) * parseFloat(mgr.royalty1))) }</td>
          <td>{ formatCurrencyZero(((parseFloat(oddJobsPaidTot) - parseFloat(oddJobsMatPaidTot)) * afterRoyalty) + ((parseFloat(oddJobsPaidTot1) - parseFloat(oddJobsMatPaidTot1)) * afterRoyalty1)) }</td>
        </tr>
        <tr>
          <td>Paint Revenue - Paid</td>
          <td>{ formatCurrencyZero(parseFloat(paintPaidTot) + parseFloat(paintPaidTot1)) }</td>
          <td>{ formatCurrencyZero(0) }</td>
          <td>{ formatCurrencyZero(parseFloat(paintPaidTot) + parseFloat(paintPaidTot1)) }</td>
          <td>{ formatCurrencyZero((parseFloat(paintPaidTot) * parseFloat(mgr.royalty)) + (parseFloat(paintPaidTot1) * parseFloat(mgr.royalty1))) }</td>
          <td>{ formatCurrencyZero((parseFloat(paintPaidTot) * afterRoyalty) + (parseFloat(paintPaidTot1) * afterRoyalty1)) }</td>
        </tr>
        <tr className='has-background-danger'>
          <td>Window Revenue - Receivable</td>
          <td>{ formatCurrencyZero(parseFloat(jobsRecTot) + parseFloat(jobsRecTot1)) }</td>
          <td>{ formatCurrencyZero(0) }</td>
          <td>{ formatCurrencyZero(parseFloat(jobsRecTot) + parseFloat(jobsRecTot1)) }</td>
          <td>{ formatCurrencyZero((parseFloat(jobsRecTot) * parseFloat(mgr.royalty)) + ( parseFloat(jobsRecTot1) * parseFloat(mgr.royalty1))) }</td>
          <td>{ formatCurrencyZero((parseFloat(jobsRecTot) * afterRoyalty) + (parseFloat(jobsRecTot1) * afterRoyalty1)) }</td>
      </tr>
        <tr className='has-background-danger'>
          <td>Odd Job Revenue - Receivable</td>
          <td>{ formatCurrencyZero(parseFloat(oddJobsRecTot) + parseFloat(oddJobsRecTot1)) }</td>
          <td>{ formatCurrencyZero(parseFloat(oddJobsMatRecTot) + parseFloat(oddJobsMatRecTot1)) }</td>
          <td>{ formatCurrencyZero((parseFloat(oddJobsRecTot) - parseFloat(oddJobsMatRecTot)) + (parseFloat(oddJobsRecTot1) - parseFloat(oddJobsMatRecTot1))) }</td>
          <td>{ formatCurrencyZero(((parseFloat(oddJobsRecTot) - parseFloat(oddJobsMatRecTot)) * parseFloat(mgr.royalty)) + ((parseFloat(oddJobsRecTot1) - parseFloat(oddJobsMatRecTot1)) * parseFloat(mgr.royalty1))) }</td>
          <td>{ formatCurrencyZero(((parseFloat(oddJobsRecTot) - parseFloat(oddJobsMatRecTot)) * afterRoyalty) + ((parseFloat(oddJobsRecTot1) - parseFloat(oddJobsMatRecTot1)) * afterRoyalty1)) }</td>
        </tr>
        <tr className='has-background-danger'>
          <td>Paint Revenue - Receivable</td>
          <td>{ formatCurrencyZero(paintRecTot + paintRecTot1) }</td>
          <td>{ formatCurrencyZero(0) }</td>
          <td>{ formatCurrencyZero(paintRecTot + paintRecTot1) }</td>
          <td>{ formatCurrencyZero((paintRecTot * parseFloat(mgr.royalty)) + (paintRecTot1 * parseFloat(mgr.royalty1))) }</td>
          <td>{ formatCurrencyZero((paintRecTot * afterRoyalty) + (paintRecTot1 * afterRoyalty1)) }</td>

        </tr>
        <tr className='has-background-primary'>
          <td>Total - Paid</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{ formatCurrencyZero(totalEarned) }</td>
        </tr>
        <tr className='has-background-danger'>
          <td>Total - Receivable</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{ formatCurrencyZero(totalReceivable) }</td>
        </tr>
        <tr className='has-background-link'>
          <td>Total - Paid and Receivable</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>{ formatCurrencyZero(totalEarnedAndReceivable) }</td>
        </tr>
      </tbody>
    )

    const tableLabourData = () =>
      (
       <tbody>
         <tr>
           <td>Labour - Recorded</td>
           <td>{ formatCurrencyZero(wss) }</td>
           <td>{ formatCurrencyZero(totalBurden(wss) - wss) }</td>
           <td>{ formatCurrencyZero(totalBurden(wss)) }</td>
         </tr>
         <tr>
           <td>Labour - Pending</td>
           <td>{ formatCurrencyZero(mts) }</td>
           <td>{ formatCurrencyZero(totalBurden(mts) - mts) }</td>
           <td>{ formatCurrencyZero(totalBurden(mts)) }</td>
         </tr>
         <tr className='has-background-primary'>
           <td>Total Labour</td>
           <td></td>
           <td></td>
           <td>{ formatCurrencyZero(totalBurden(wss) + totalBurden(mts)) }</td>
         </tr>
       </tbody>
     )

  const expensesTable = () => (
    <React.Fragment>
      <h1 className='subtitle has-text-weight-bold has-text-info'>Expenses</h1>
      <table className='table'>
        <thead>
          <tr>
            <th>Expenses</th>
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th>Total</th>
          </tr>
        </thead>
        <tbody>
          <tr className='has-background-primary'>
            <td>Expenses</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>{ formatCurrencyZero(expensesTotal) }</td>
          </tr>
        </tbody>
      </table><br/>
      <hr/>
    </React.Fragment>
  )

  const payoutDataTotal = () => {
    let totalNet = 0;
    let totalCPP = 0;
    let totalEmpCPP = 0;
    let totalEI = 0;
    let totalEmpEI = 0;
    let totalTax = 0;
    let totalHST = 0;
    let totalGross = 0;
    let totalEHT = 0;
    let totalWSIB = 0;

    payouts.forEach((payout) => {
      totalNet += parseFloat(payout.net);
      totalCPP += parseFloat(payout.wkr_cpp);
      totalEmpCPP += parseFloat(payout.emp_cpp);
      totalEI += parseFloat(payout.wkr_ei);
      totalEmpEI += parseFloat(payout.emp_ei);
      totalTax += parseFloat(payout.wkr_tax);
      totalHST += parseFloat(payout.hst);
      totalGross += parseFloat(payout.gross);
      totalEHT += parseFloat(payout.eht);
      totalWSIB += parseFloat(payout.wsib);
    })

    return <tr key='total'>
             <th>Total</th>
             <th></th>
             <th>{ formatCurrencyZero(totalNet) }</th>
             <th>{ formatCurrencyZero(totalCPP) }</th>
             <th>{ formatCurrencyZero(totalEI) }</th>
             <th>{ formatCurrencyZero(totalTax) }</th>
             <th>{ formatCurrencyZero(totalHST) }</th>
             <th>{ formatCurrencyZero(totalGross) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalEmpCPP) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalEmpEI) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalEHT) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalWSIB) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalEHT + totalWSIB + totalEmpEI + totalEmpCPP) }</th>
             <th className='has-background-primary'>{ formatCurrencyZero(totalGross + totalEHT + totalWSIB + totalEmpEI + totalEmpCPP) }</th>
           </tr>
  }


  const payoutsTable = () => (
    <React.Fragment>
      <h1 className='subtitle has-text-weight-bold has-text-info'>Payouts</h1>
      <div className='table-container'>
        <table className='table'>
          <thead>
            <tr>
              <th>Date</th>
              <th>Chq Num</th>
              <th>Net</th>
              <th>CPP</th>
              <th>EI</th>
              <th>Tax</th>
              <th>HST</th>
              <th>Net Gross</th>
              <th className='has-background-primary'>Emp CPP</th>
              <th className='has-background-primary'>Emp EI</th>
              <th className='has-background-primary'>EHT</th>
              <th className='has-background-primary'>WSIB</th>
              <th className='has-background-primary'>Burden</th>
              <th className='has-background-primary'>Gross</th>
            </tr>
          </thead>
          <tbody>
            { payouts && payoutDataTotal() }
          </tbody>
        </table>
      </div><br/>
    </React.Fragment>
  )

  const balanceTable = () =>
      <React.Fragment>
        <h1 className='subtitle has-text-weight-bold has-text-info'>Balance</h1>
        <table className='table'>
          <thead>
            <tr>
              <th colSpan='5'>Revenue-Prod</th>
              <th>{ formatCurrencyZero(totalEarnedAndReceivable) }</th>
            </tr>
            <tr>
              <th colSpan='5'>Revenue-Ests</th>
              <th>{ formatCurrencyZero(parseFloat(estsSignsVisitTotal) + parseFloat(estsSignsSalesTotal)) }</th>
            </tr>
            <tr>
              <th colSpan='5'>Revenue-Misc</th>
              <th>{ formatCurrencyZero(parseFloat(personalWssTotal) + parseFloat(personalMtsTotal)) }</th>
            </tr>
            <tr className='has-background-link'>
              <th colSpan='5'>Total Revenue</th>
              <th>{
                formatCurrencyZero(parseFloat(personalWssTotal) + parseFloat(personalMtsTotal)  +
                parseFloat(estsSignsVisitTotal) + parseFloat(estsSignsSalesTotal) +
                parseFloat(totalEarnedAndReceivable))
              }</th>
            </tr>
            <tr>
              <th colSpan='5'>Labour</th>
              <th>{ formatCurrencyZero(totalBurden(wss) + totalBurden(mts)) }</th>
            </tr>
            <tr>
              <th colSpan='5'>Expenses</th>
              <th>{ formatCurrencyZero(expensesTotal) }</th>
            </tr>
            <tr className='has-background-primary'>
              <th colSpan='5'>Total Expenses</th>
              <th>{ formatCurrencyZero(totalBurden(wss) + totalBurden(mts) + parseFloat(expensesTotal)) }</th>
            </tr>
            <tr className='has-background-primary'>
              <th colSpan='5'>Total Payout</th>
              <th>{ formatCurrencyZero(payoutsTotal) }</th>
            </tr>
            <tr className='has-background-warning'>
              <th colSpan='5'>Balance</th>
              <th>{ formatCurrencyZero(parseFloat(personalWssTotal) + parseFloat(personalMtsTotal)  +
              parseFloat(estsSignsVisitTotal) + parseFloat(estsSignsSalesTotal) +
              parseFloat(totalEarnedAndReceivable) - (totalBurden(wss) + totalBurden(mts) + parseFloat(expensesTotal)) - parseFloat(payoutsTotal)) }</th>
            </tr>
            <tr className='has-background-danger'>
              <th colSpan='5'>Receivable Total</th>
              <th>{ formatCurrencyZero(totalReceivable) }</th>
            </tr>
            <tr className='has-background-warning'>
              <th colSpan='5'>Available Balance</th>
              <th>{ formatCurrencyZero(parseFloat(personalWssTotal) + parseFloat(personalMtsTotal)  +
              parseFloat(estsSignsVisitTotal) + parseFloat(estsSignsSalesTotal) +
              parseFloat(totalEarnedAndReceivable) - (totalBurden(wss) + totalBurden(mts) + parseFloat(expensesTotal)) - parseFloat(payoutsTotal) - parseFloat(totalReceivable)) }</th>
            </tr>
          </thead>
        </table>
      </React.Fragment>

  return (
      <div>
        { showLoader ? <Loader /> :
          (<div className='box'>
            <h1 className='title has-text-centered has-text-weight-bold has-text-info'>{ showDisplay }</h1><br/>

            <h1 className='subtitle has-text-weight-bold has-text-info'>Revenue - Production</h1>
            <table className='table'>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Price</th>
                  <th>Materials</th>
                  <th>Net Price</th>
                  <th>Royalty</th>
                  <th>Earnings</th>
                </tr>
              </thead>
              { mgr && tableRevenueData() }
            </table><br/>

          <h1 className='subtitle has-text-weight-bold has-text-info'>Revenue - Estimates and Signs</h1>
            <table className='table'>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Site Visit</td>
                  <td>{ formatCurrencyZero(estsSignsVisitTotal) }</td>
                </tr>
                <tr>
                  <td>Sales Pay</td>
                  <td>{ formatCurrencyZero(estsSignsSalesTotal) }</td>
                </tr>
                <tr className="has-background-link">
                  <td>Total</td>
                  <td>{ formatCurrencyZero(parseFloat(estsSignsVisitTotal) + parseFloat(estsSignsSalesTotal)) }</td>
                </tr>
              </tbody>
            </table><br/>

            <h1 className='subtitle has-text-weight-bold has-text-info'>Revenue - Misc</h1>
            <table className='table'>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Workschedule</td>
                  <td>{ formatCurrencyZero(personalWssTotal) }</td>
                </tr>
                <tr>
                  <td>Mgt Tasks</td>
                  <td>{ formatCurrencyZero(personalMtsTotal) }</td>
                </tr>
                <tr className="has-background-link">
                  <td>Total</td>
                  <td>{ formatCurrencyZero(parseFloat(personalWssTotal) + parseFloat(personalMtsTotal)) }</td>
                </tr>
              </tbody>
            </table><br/>

            <hr/>

            <h1 className='subtitle has-text-weight-bold has-text-info'>Labour</h1>
            <table className='table'>
              <thead>
                <tr>
                  <th>Type</th>
                  <th>Cost</th>
                  <th>Burden</th>
                  <th>Total</th>
                </tr>
              </thead>
              { tableLabourData() }
            </table><br/>

            <hr/>

            { expensesTable() }

            { payoutsTable() }

            { balanceTable() }

          </div>)
        }
      </div>
    )

}

export default Report;
