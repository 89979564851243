import React, { useState, useEffect } from 'react';

import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
dayjs.extend(customParseFormat);
import { useStateReducer } from "../../../base/context/authContext";



import ApiClient from "../../../base/api/client";
import { ErrorMsg } from "../../components/ErrorMsg"

const JobDate = ({
  register,
  fltrDateClass,
  apptDateClass,
  errorMsgs,
  onChangeJobSpecs,
  eavesFltrCheckedClass,
  eavesApptCheckedClass,
  eavesFltrClass,
  eavesApptClass,
  changeStatus,
  type
}) => {

  const [{ jobinfoid }, dispatch] = useStateReducer();
  const [dates, setDates] = useState(null);
  const [bookings, setBookings] = useState(null)
  const [limits, setLimits] = useState(null)
  const [datesNext, setDatesNext] = useState(null);
  const [bookingsNext, setBookingsNext] = useState(null)
  const [limitsNext, setLimitsNext] = useState(null)

  const fetchWindow = () => {
    const fetchApptOptions = async () => {
      const response = await ApiClient.request(`/api/sales/${jobinfoid}/appt_limits`); // FALL appt_limits
      setLimits(response.limits)
      const datesResponse = Object.keys(response.bookings);
      setDates(datesResponse);
      const valuesResponse = Object.values(response.bookings);
      const temp = valuesResponse.map(vals => {
        let eights = 0;
        let twelves = 0;
        let anys = 0 ;
        vals.forEach(val => {
          if(val.stime.includes("8:00")){
            eights += 1;
          } else if(val.stime.includes("12:00")){
            twelves += 1;
          } else {
            anys += 1;
          }
        })
        return {
          eights,
          twelves,
          anys
        }
      })

      setBookings(temp);


      const responseNext = await ApiClient.request(`/api/sales/${jobinfoid}/appt_limits_next`); // SUMMER
      setLimitsNext(responseNext.limits)
      const datesResponseNext = Object.keys(responseNext.bookings);
      setDatesNext(datesResponseNext);
      const valuesResponseNext = Object.values(responseNext.bookings);
      const tempNext = valuesResponseNext.map(vals => {
        let eights = 0;
        let twelves = 0;
        let anys = 0 ;
        vals.forEach(val => {
          if(val.stime.includes("8:00")){
            eights += 1;
          } else if(val.stime.includes("12:00")){
            twelves += 1;
          } else {
            anys += 1;
          }
        })
        return {
          eights,
          twelves,
          anys
        }
      })

      setBookingsNext(tempNext);
    }

    useEffect(()=>{
      if(jobinfoid!==null){
        fetchApptOptions();
      }
    }, [jobinfoid])


    const createReadableDate = (date, time) => {
      return dayjs(date).format("ddd MMM DD") + ` [${time}]`;
    }

    const summerApptOptions = () => {
      console.log('DatesNext:', datesNext);
      let ans = datesNext.map((day, index) => {
        const dailyBookings = bookingsNext ? bookingsNext[index] : 0;
        return(
          <React.Fragment key={index}>
            { dailyBookings.eights < limitsNext.eights ? (<option key={`${day}8:00`}> {createReadableDate(day, '8-10am Arrival')}</option>) : null}
            { dailyBookings.twelves < limitsNext.twelves ? <option key={`${day}12:00`}> {createReadableDate(day, '12-2pm Arrival')}</option> : null}
            { dailyBookings.anys < limitsNext.anys ? <option key={`${day}Any`}> {createReadableDate(day, 'Anytime')}</option> : null}
          </React.Fragment>
        )
      })
      ans.unshift(
         <option key="None Selected">None Selected</option>
       )
      return (
        <select className='select' name='apptdate' id='apptDate' ref={register}  onChange={onChangeJobSpecs} >
          { ans }
        </select>
      )
    }


    const fallApptOptions = () => {
      //const ans = [];
      console.log('Dates:', dates);
      const ans = dates.map((day, index) => {
        const dailyBookings = bookings ? bookings[index] : 0;
        return(
          <React.Fragment key={index}>
            { dailyBookings.eights < limits.eights ? <option key={`${day}8:00`}> {createReadableDate(day, '8-10am Arrival')}</option> : null}
            { dailyBookings.twelves < limits.twelves ? <option key={`${day}12:00`}> {createReadableDate(day, '12-2pm Arrival')}</option> : null}
            { dailyBookings.anys < limits.anys ? <option key={`${day}Any`}> {createReadableDate(day, 'Anytime')}</option> : null}
          </React.Fragment>
        )
      })
      ans.unshift(
         <option key="None Selected">None Selected</option>
         // <option key="None Selected">Please call/email our office to book an appt</option>
       )

      return (
        <select className='select' name='apptdate' id='apptDate' ref={register}  onChange={onChangeJobSpecs} >
          { ans }
        </select>
      )
    }



    switch(type){
      case 'WindowsSummer':
        return (
          <div>
            <div className={fltrDateClass}>
              <div className='control'>
                <label className='label' htmlFor='floatRange'>Floater Date</label>
                <div className='select'>
                  <select className='select' name='floatrange' id='floatRange' ref={register} onChange={onChangeJobSpecs} >
                    <option value="None Selected">None Selected</option>
                    <option value="Sep 1-15">Sep 1-15 [2024]</option>
                    <option value="Sep 16-30">Sep 16-30 [2024]</option>
                  </select>
                </div>
                <ErrorMsg msg={errorMsgs.dateMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
              </div>
            </div>

            <div className={apptDateClass}>
              <div className='control'>
                <label className='label' htmlFor='apptDate'>Appointment Date</label>
                <div className='select'>
                  { datesNext && limitsNext && summerApptOptions() }
                </div>
                <h3 className='mt-3'>416-363-7420 / customerservice@whiteshark.ca</h3>
                <ErrorMsg msg={errorMsgs.dateMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
              </div>
            </div>
          </div>
      )

      case 'WindowsFall':
        return (
          <div>
            <div className={fltrDateClass}>
              <div className='control'>
                <label className='label' htmlFor='floatRange'>Floater Date</label>
                <div className='select'>
                  <select className='select' name='floatrange' id='floatRange' ref={register} onChange={onChangeJobSpecs} >
                    <option value="None Selected">None Selected</option>
                    <option value="Oct 1-15">Oct 1-15 [2024]</option>
                    <option value="Oct 16-31">Oct 16-31 [2024]</option>
                    <option value="Nov 1-10">Nov 1-10 [2024]</option>
                    <option value="Dec 5-23">Dec 5-23 [2024]</option>
                  </select>
                </div>
                <h3 className='mt-3'>416-363-7420 / customerservice@whiteshark.ca</h3>
                <ErrorMsg msg={errorMsgs.dateMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
              </div>
            </div>

            <div className={apptDateClass}>
              <div className='control'>
                <label className='label' htmlFor='apptDate'>Appointment Date</label>
                <div className='select'>
                  { dates && limits && fallApptOptions() }
                </div>
                <h3 className='mt-3'>416-363-7420 / customerservice@whiteshark.ca</h3>
                <ErrorMsg msg={errorMsgs.dateMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
              </div>
            </div>
          </div>
      )

      case 'EavesSummer':
        return(
          <div>
          <div className='field'>
            <div className="control">
              <label className="radio">
                <input type="radio" name="answer" checked={eavesFltrCheckedClass} onChange={changeStatus} />
                <span className='ml-1'>Floater(Date Range Options Shown Below)</span>
              </label>
              <label className="radio">
                <input type="radio" name="answer" checked={eavesApptCheckedClass} onChange={changeStatus} />
                <span className='ml-1'>Appointment</span>
              </label>
            </div>
          </div>

          <div className={eavesFltrClass}>
            <div className='control'>
              <label className='label' htmlFor='floatRange'>Floater Date</label>
              <div className='select'>
                <select className='select' name='floatrange' id='floatRange' ref={register}>
                  <option value="None Selected">None Selected</option>
                  <option value="Sep 1-15">Sep 1-15 [2024]</option>
                  <option value="Sep 16-30">Sep 16-30 [2024]</option>
                </select>
              </div>
              <ErrorMsg msg={errorMsgs.dateMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
            </div>
          </div>

          <div className={eavesApptClass}>
            <div className='control'>
              <label className='label' htmlFor='apptDate'>Appointment Date</label>
              <div className='select'>
                { datesNext && limitsNext && summerApptOptions() }
              </div>
            </div>
            <h3 className='mt-3'>416-363-7420 / customerservice@whiteshark.ca</h3>
            <ErrorMsg msg={errorMsgs.dateMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
        </div>
        </div>
      )

      case 'EavesFall':
        return(
          <div>
          <div className='field'>
            <div className="control">
              <label className="radio">
                <input type="radio" name="answer" checked={eavesFltrCheckedClass} onChange={changeStatus} />
                <span className='ml-1'>Floater(Date Range Options Shown Below)</span>
              </label>
              <label className="radio">
                <input type="radio" name="answer" checked={eavesApptCheckedClass} onChange={changeStatus} />
                <span className='ml-1'>Appointment</span>
              </label>
            </div>
          </div>

          <div className={eavesFltrClass}>
            <div className='control'>
              <label className='label' htmlFor='floatRange'>Floater Date</label>
              <div className='select'>
                <select className='select' name='floatrange' id='floatRange' ref={register}>
                  <option value="None Selected">None Selected</option>
                  <option value="Nov 11-23">Nov 11-23 [2024]</option>
                  <option value="Nov 24-Dec 4">Nov 24-Dec 4 [2024]</option>
                  <option value="Dec 5-15">Dec 5-15 [2024]</option>
                  <option value="Dec 16-31">Dec 16-31 [2024]</option>
                </select>
              </div>
              <h3 className='mt-3'>416-363-7420 / customerservice@whiteshark.ca</h3>
              <ErrorMsg msg={errorMsgs.dateMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
            </div>
          </div>

          <div className={eavesApptClass}>
            <div className='control'>
              <label className='label' htmlFor='apptDate'>Appointment Date</label>
              <div className='select'>
                { dates && limits && fallApptOptions() }
              </div>
              <h3 className='mt-3'>416-363-7420 / customerservice@whiteshark.ca</h3>
              <ErrorMsg msg={errorMsgs.dateMustBeFilled} num={ Object.keys(errorMsgs).length-1 }/>
            </div>
          </div>
        </div>
        )

    }
  }

  return (
    <div>{ type && fetchWindow() }</div>
  )

}

export default JobDate;



// { dates && limits && fallApptOptions() }
// { datesNext && limitsNext && summerApptOptions() }
// { datesNext && limitsNext && summerApptOptions() }


// <option value="None Selected">Please call/email our office to book an appt</option>

// <option value="None Selected">Please call/email our office to book an appt</option>

// <option value="None Selected">Please call/email our office to book an appt</option>


// <select>
//   <option value="None Selected">Please call/email our office to book an appt</option>
// </select>

// <select className='select' name='apptdate' id='apptDate' ref={register}>
//   <option value="None Selected">Please call/email our office to book an appt</option>
// </select>

// <option value="Nov 1-10">Nov 1-10 [2022]</option>
// <option value="Oct 16-31">Oct 16-31 [2022]</option>
// <option value="None Selected">None Selected</option>
// <option value="Nov 11-23">Nov 11-23 [2022]</option>
// <option value="Nov 24-Dec 4">Nov 24-Dec 4 [2022]</option>
// <option value="Dec 5-15">Dec 5-15 [2022]</option>
// <option value="May 1-15">May 1-15 [2023]</option>
// <option value="May 1-15">May 1-15 [2023]</option>

// <option value="Sep 16-30">Sep 16-30 [2023]</option>


// <option value="Oct 1-15">Oct 1-15 [2023]</option>
// <option value="Oct 16-31">Oct 16-31 [2023]</option>

// <option value="Apr 1-15">Apr 1-15 [2024]</option>
// <option value="Apr 1-15">Apr 1-15 [2024]</option>


// <option value="Apr 16-30">Apr 16-30 [2024]</option>
// <option value="May 1-15">May 1-15 [2024]</option>
// <option value="Apr 16-30">Apr 16-30 [2024]</option>
// <option value="May 1-15">May 1-15 [2024]</option>
// <option value="May 16-31">May 16-31 [2024]</option>

// <option value="Jun 1-15">Jun 1-15 [2024]</option>
// <option value="Jun 16-30">Jun 16-30 [2024]</option>
// <option value="Jul 1-15">Jul 1-15 [2024]</option>
// <option value="Jul 16-31">Jul 16-31 [2024]</option>

// <option value="Aug 1-15">Aug 1-15 [2024]</option>
// <option value="Aug 16-31">Aug 16-31 [2024]</option>
// <option value="Sep 16-30">Sep 16-30 [2024]</option>

















// Floater- Date Range Options Shown Below
