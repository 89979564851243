import React, { useState, useEffect } from 'react';
import { useForm } from "react-hook-form";

const LadderMgt = ({handleDailyReport, user}) => {
  const ccForm = useForm();
  const [currentDate, setCurrentDate] = useState(null);

  useEffect(() => {
    const date = new Date();
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    if(month.toString().length === 1){
      month = '0' + month.toString();
    }
    if(day.toString().length === 1){
      day = '0' + day.toString();
    }
    // This arrangement can be altered based on how we want the date's format to appear.
    let currentDate = `${year}-${month}-${day}`
    console.log('Date: ', currentDate);
    setCurrentDate(currentDate);
  }, [])

  const { register, handleSubmit, setValue, getValues } = ccForm;
  const {
    daily_notes,
    daily_a_notes,
  } = getValues();

  return(
    <div>
      <h2 className='title is-3 has-text-centered'>Ladder Mgt [{ user && user.user_name }] </h2>
      <form className='form' onSubmit={handleSubmit(handleDailyReport)}>
        <input
          type='hidden'
          name='hrid'
          ref={ register }
          defaultValue = { user && user.hrid }
        />
        <input
          type='hidden'
          name='area'
          ref={ register }
          defaultValue = { 'Ladder Mgt' }
        />

        <div className='field'>
          <div className='control'>
            <input
              type='date'
              name='date'
              ref={ register }
              defaultValue = { currentDate }
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line1'
              ref={ register }
              defaultValue={ "[Set up truck ladder assignments in system]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line2'
              ref={ register }
              defaultValue={ "[Scrap/repair damaged ladders]"}
            />
          </div>
        </div><br/>

        <div className='field'>
          <div className='control'>
            <textarea
              className='textarea'
              rows='2'
              name='line3'
              ref={ register }
              defaultValue={ "[Order new ladders and label them and record in system]"}
            />
          </div>
        </div><br/>

        <div className='has-text-centered'>
            <button type='submit' className='button is-link is-rounded has-text-weight-bold'>Send Ladder Mgt Report</button>
        </div>
      </form>
    </div>

  )


}

export default LadderMgt
